import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")
  const _component_Avatar = _resolveComponent("Avatar")
  const _component_AvatarBackground = _resolveComponent("AvatarBackground")
  const _component_AvatarRatio = _resolveComponent("AvatarRatio")
  const _component_Label = _resolveComponent("Label")
  const _component_Badge = _resolveComponent("Badge")
  const _component_Styles = _resolveComponent("Styles")
  const _component_Sizes = _resolveComponent("Sizes")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_Avatar),
      _createVNode(_component_AvatarBackground),
      _createVNode(_component_AvatarRatio),
      _createVNode(_component_Label),
      _createVNode(_component_Badge),
      _createVNode(_component_Styles),
      _createVNode(_component_Sizes)
    ])
  ]))
}